import React, {useCallback, useState, useEffect} from 'react';
import Form from 'rev.sdk.js/Generic/Form';
import {useOutlet} from 'reconnect.js';
import Button from '../Widgets/Button';
import RichTextEditor, {Provider} from 'rev.sdk.js/Components/RichTextEditor';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {message} from 'antd';
import styled from 'styled-components';
import {errorHandler} from '../errors';
const qs = require('query-string');

function RichTextField(props) {
  const {formData, onChange, schema} = props;

  return (
    <div>
      <div
        style={{backgroundColor: '#fff', minHeight: 200, padding: '4px 11px'}}>
        <Provider
          content={formData}
          onChange={(value) => {
            onChange(value);
          }}>
          <RichTextEditor />
        </Provider>
      </div>
    </div>
  );
}

const schema = {
  title: '',
  type: 'object',
  properties: {
    variant: {
      title: '成立訂單前注意事項',
      type: 'array',
      items: {
        type: 'object',
      },
      default: [
        {
          type: 'p',
          children: [{text: ''}],
        },
      ],
    },
  },
};

const uiSchema = {
  variant: {
    'ui:field': RichTextField,
  },
};

export default function EditCustomerService({location}) {
  const [actions] = useOutlet('actions');
  const [loading, setLoading] = useOutlet('loading');
  const [token] = useOutlet('jwtToken');
  const [isSchemaLoaded, setIsSchemaLoaded] = useState(false);
  const [formSchema, setFormSchema] = useState(schema);
  const {key} = qs.parse(location.search);

  useEffect(() => {
    (async () => {
      if (key) {
        setLoading(true);
        try {
          let {content} = await actions.getCustomerServiceInfo(key);
          setFormSchema((prevSchema) => ({
            ...prevSchema,
            properties: {
              ...prevSchema.properties,
              variant: {
                ...prevSchema.properties.variant,
                default: content,
              },
            },
          }));
          setIsSchemaLoaded(true);
        } catch (err) {
          errorHandler(err);
        }
        setLoading(false);
      } else {
        setFormSchema(schema);
        setIsSchemaLoaded(true);
      }
    })();
  }, [actions, key]);

  const submit = useCallback(
    async (data) => {
      setLoading(true);
      try {
        await actions.updateCustomerServiceInfo({
          key: 'agreement',
          content: data.variant,
          token,
        });
        message.success('儲存成功');
        actions.navigate('/customer_service_info/');
      } catch (err) {
        message.error('儲存錯誤');
      }
      setLoading(false);
    },
    [token, actions],
  );

  return (
    <Wrapper>
      <h2>編輯成立訂單前注意事項</h2>
      {isSchemaLoaded ? (
        <Form
          schema={formSchema}
          uiSchema={uiSchema}
          onSubmit={async (formData) => {
            submit(formData);
          }}
          renderCustomSubmitButton={(
            {values, instance, setValues},
            submitBtnRef,
          ) => (
            <Button onClick={() => submitBtnRef.current?.click()}>保存</Button>
          )}
        />
      ) : (
        <p>載入中...</p>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background-color: #f2f2f2;
  padding: 20px;
`;
